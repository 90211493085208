import {
  collection, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';

export default async function loadLastOperation(
  tankId: string,
  globalState: any,
  globalActions: any,
  t: any,
  firestore: any,
  reset: any,
  defaultValues: any,
) {
  const operationsRef = collection(firestore, `organizations/${globalState.activeOrganization}/apps/`
    + 'digitank-gauged-containers/operations');

  const queryDocs = query(
    operationsRef,
    where('tankNumber', '==', tankId),
    orderBy('dateAndTime', 'desc'),
    limit(1),
  );
  const snapshot = await getDocs(queryDocs);
  if (!snapshot.empty) {
    const fsOperation = snapshot.docs[0].data();

    if(fsOperation.form && fsOperation.form.version === 2) {
      reset(fsOperation.form);
    } else {
      const form = fsOperation.form;
      const formValuesFromOldOperation = formFromOldOperation(form);
      const mergedFormValues = deepMerge(defaultValues, formValuesFromOldOperation);
      reset(mergedFormValues);
    }

    return;
  }
  globalActions.setSnackbarMessage({ message: t('operation.no_saved_operation'), severity: 'warning' });
}

function formFromOldOperation(form: any) {
  return {
    height: {
      value: form.fields.height.value,
    },
    volume: {
      value: form.fields.volume.value,
    },
    volumeToAdd: {
      isAddVolume: form.volumeChange == 'add' ? true : false,
      value: form.volumeToAdd?.value || '',
    },
    tavInputs: {
      measuredTav: {
        value: form.results.volumeConvResult?.measuredTav?.value || '',
      },
      tavTemperature: {
        value: form.results.volumeConvResult?.tavTemperature?.value || '',
      },
      liquidTemperature: {
        value: form.results.volumeConvResult?.liquidTemperature?.value || '',
      },
    },
    results: {
      volume: {
        value: form.results.volume,
      },
      height: {
        value: form.results.height,
      },
    },
    paConversionResults: {
      abvAt20c: { 
        value: form.results.volumeConvResult?.abvAt20c?.value || '', 
      },
      volumeAtTemp: {
        temperature: { 
          value: form.results.volumeConvResult?.volumeAtTemp?.temperature?.value || '', 
        },
        value: form.results.volumeConvResult?.volumeAtTemp?.value || '', 
      },
      volumeAt20c: { 
        temperature: { 
          value: form.results.volumeConvResult?.volumeAt20c?.temperature?.value || '', 
        },
        value: form.results.volumeConvResult?.volumeAt20c?.value || '', 
      },
      pureAlcoholVolumeAt20c: { 
        value: form.results.volumeConvResult?.volumeAt20c?.value || '', 
      },
      extraFields: form.extraFields,
    }
  }
}

function deepMerge(obj1: any, obj2: any) {
  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
        obj1[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
  }
  return obj1;
}

