import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import MoreIcon from '@mui/icons-material/MoreVert';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  collection, getDocs, query,
} from 'firebase/firestore';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Zoom } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import useGlobal from 'global-state/store';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import {
  CellarDetails,
  CellarWarningDelete,
} from './CellarDetails';
import CellarCreation from './CellarCreation';

export default function Cellars() {
  const { t } = useTranslation();
  const db = useFirestore();
  const analytics = getAnalytics();
  const { currentUser: user } = useAuth();
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [cellars, setCellars] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [openError, setOpenError] = React.useState(false);
  const [activeCellar, setActiveCellar] = React.useState({});
  const [globalState] = useGlobal();

  React.useEffect(() => {
    async function getCellarsOfClient(firestore, path) {
      const cellarsQuery = query(
        collection(firestore, path, 'cellars'),
      );
      const querySnapshot = await getDocs(cellarsQuery);
      const cellarDocs = querySnapshot.docs;
      const allCellars = cellarDocs.map((cellarDoc) => cellarDoc.data());
      setCellars(allCellars);
      setLoaded(true);
      logEvent(analytics, 'loading_cellars', {
        user_uid: user?.uid,
        client_path: path,
      });
    }

    async function getCellarsByUser() {
      try {
        getCellarsOfClient(
          db,
          globalState.clientPath,
        );
      } catch (e) {
        logEvent(analytics, 'error_loading_cellars', {
          user_uid: user?.uid,
          error_message: e.message,
        });
        setErrorMessage(e.message);
        setOpenError(true);
      }
    }

    if (user?.uid !== undefined && globalState.clientPath !== '' && loaded === false) {
      getCellarsByUser();
    }
  }, [db, user?.uid, loaded, analytics, globalState.clientPath]);

  const handleOpen = (cellar) => {
    setActiveCellar(cellar);
    setOpenDetails(true);
  };

  const handleCreate = () => {
    setOpenCreate(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Snackbar
        open={openError}
        style={{ marginTop: 200 }}
        autoHideDuration={15000}
        onClose={() => { setOpenError(false); setErrorMessage(''); }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert style={{ padding: 50 }} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.cellars_management')}
      </Typography>
      {/* <TankFilters /> */}
      <ListHeader />
      {cellars.length >= 100
        && <Alert severity="warning">{t('admin.too_many')}</Alert>}
      <Paper elevation={0} sx={{ p: 1 }}>
        {loaded && (
          <List dense sx={{ width: '100%' }}>
            {cellars.map((cellar) => (
              <div key={cellar.id}>
                <ListCellar
                  cellar={cellar}
                  handleOpen={handleOpen}
                />
                <Divider />
              </div>
            ))}
          </List>
        )}
        {!loaded && <CircularProgress />}
      </Paper>
      <CellarDetails
        open={openDetails}
        setOpen={setOpenDetails}
        cellar={activeCellar}
        setOpenWarning={setOpenWarning}
      />
      <CellarCreation
        open={openCreate}
        setOpen={setOpenCreate}
        setCellars={setCellars}
        cellars={cellars}
      />
      <CellarWarningDelete
        open={openWarning}
        setOpen={setOpenWarning}
        cellar={activeCellar}
        setCellars={setCellars}
      />
      <Zoom
        key="primary"
        in={!openCreate}
        unmountOnExit
      >
        <Fab
          color="primary"
          aria-label="download"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          onClick={handleCreate}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Box>
  );
}

function ListCellar(props) {
  const { handleOpen, cellar } = props;
  return (
    <ListItem key={cellar.id} component="div">
      <ListItemButton onClick={() => handleOpen(cellar)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography>{cellar.id}</Typography>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <MoreIcon />
          </IconButton>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

function ListHeader() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography>{t('admin.cellar')}</Typography>
    </Box>
  );
}
