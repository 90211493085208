import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, Dialog, List, ListItem, Paper, Typography,
} from '@mui/material';
import useGlobal from 'global-state/store';
import {
  collection, getDocs, onSnapshot, orderBy, query,
} from 'firebase/firestore';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import { notificationItemFactory, notificationDetailFactory } from './notificationFactory';

export default function Notifications() {
  const [globalState] = useGlobal();
  const { currentUser: user } = useAuth();

  return (
    <Paper sx={{
      p: 3,
    }}
    >
      {(!user || !globalState.activeOrganization) ? (
        <CircularProgress />
      ) : (<NotificationsList />) }
    </Paper>
  );
}

function NotificationsList() {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [globalState] = useGlobal();
  const firestore = useFirestore();
  const [status, setStatus] = useState('loading');
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  const fetchNotifications = useCallback(async () => {
    setStatus('loading');
    setError(null);

    try {
      const notificationsRef = collection(
        firestore,
        `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/notifications`,
      );

      const notificationsQuery = query(notificationsRef, orderBy('dateAndTime', 'desc'));

      // Initial fetch with getDocs
      const querySnapshot = await getDocs(notificationsQuery);
      const fetchedNotifications = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id, // Include the document ID
      }));
      setNotifications(fetchedNotifications);
      setStatus('success');

      // Set up real-time updates with onSnapshot
      const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
        const liveNotifications = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id, // Include the document ID
        }));
        setNotifications(liveNotifications);
      }, (err) => {
        console.error('Error fetching notifications:', err);
        setError(err);
        setStatus('error');
      });

      return () => {
        unsubscribe();
      };
    } catch (err) {
      console.error('Error fetching notifications:', err);
      setError(err);
      setStatus('error');
      return () => {};
    }
  }, [firestore, globalState.activeOrganization]);

  useEffect(() => {
    const unsubscribe = fetchNotifications();

    // Cleanup on component unmount
    return () => {
      if (unsubscribe instanceof Function) {
        unsubscribe();
      }
    };
  }, [fetchNotifications]);

  if (status !== 'success') {
    return <CircularProgress />;
  }

  const handleOpenDetail = (notification) => {
    setSelectedNotification(notification);
  };

  const handleCloseDetail = () => {
    setSelectedNotification(null);
  };

  if (status === 'error') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Button variant="contained" onClick={fetchNotifications}>
          Réessayer
        </Button>
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6">
        Notifications
      </Typography>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {notifications.map((notification) => {
          const NotificationItem = notificationItemFactory(notification);
          return (
            <ListItem
              sx={{
                p: 0,
              }}
              key={notification.id}
              button
              onClick={() => handleOpenDetail(notification)}
            >
              {NotificationItem}
            </ListItem>
          );
        })}
      </List>

      <Dialog open={selectedNotification !== null} onClose={handleCloseDetail}>
        {selectedNotification && notificationDetailFactory(selectedNotification, handleCloseDetail)}
      </Dialog>
    </Box>
  );
}
