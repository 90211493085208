import React, { useState } from 'react';
import {
  Typography,
  Paper,
  IconButton,
  Snackbar,
  Box,
} from '@mui/material';
import Info from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import CalculateIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function ResultCard(props) {
  const [openError, setOpenError] = useState(false);
  const {
    width, maxWidth, title, infoButtonText, result, actions, infoButtonIcon, child,
  } = props;
  const myWidth = width || '100%';
  const myMaxWidth = maxWidth || '100%';
  const [actionsOpen, setactionsOpen] = useState(false);
  const [actionsAnchorEl, setactionsAnchorEl] = useState(null);

  const clickActionsMenu = (event) => {
    setactionsAnchorEl(event.currentTarget);
    setactionsOpen(true);
  };

  return (
    <Paper
      sx={{
        backgroundColor: 'ourGreen.main',
        width: myWidth,
        maxWidth: myMaxWidth,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          variant="h6"
          component="span"
          sx={{ color: '#ffffff', width: '100%', textAlign: 'left' }}
        >
          {title}
        </Typography>
        {infoButtonText && (
          <IconButton
            onClick={() => setOpenError(true)}
            sx={{ color: 'white', flexShrink: 0 }}
          >
            {infoButtonIcon || <Info sx={{ fontSize: 16, p: 0, m: 0 }} />}
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 40,
        }}
      >
        <Typography
          component="span"
          sx={{ color: '#ffffff', fontWeight: 'bold', width: '100%' }}
        >
          {result}
        </Typography>
        {actions && (
          <IconButton
            onClick={clickActionsMenu}
            sx={{ color: 'white', flexShrink: 0 }}
          >
            <CalculateIcon actions={actions} />
          </IconButton>
        )}
      </Box>
      {actions && (
        <ActionsMenu
          actionsOpen={actionsOpen}
          setactionsOpen={setactionsOpen}
          actions={actions}
          actionsAnchorEl={actionsAnchorEl}
          setactionsAnchorEl={setactionsAnchorEl}
        />
      )}
      {child}
      <Snackbar
        open={openError}
        autoHideDuration={5000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="warning">{infoButtonText}</Alert>
      </Snackbar>
    </Paper>
  );
}

function ActionsMenu(props) {
  const {
    actionsOpen,
    setactionsOpen,
    actions,
    setactionsAnchorEl,
    actionsAnchorEl,
  } = props;

  const handleClose = () => {
    setactionsOpen(false);
    setactionsAnchorEl(null);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={actionsAnchorEl}
      open={actionsOpen}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {actions.map((action, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={i} onClick={action.callback}>
          {action.title}
        </MenuItem>
      ))}
    </Menu>
  );
}
