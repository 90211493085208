import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';
import { downloadCsvFile } from '../../utils';
import OperationDetails from './OperationDetails';
import OperationVolumeConversion from './OperationVolumeConversion';
import OperationDetailsV2 from './OperationDetailsV2';
import { formatCompartmentForCsv, formatCompartmentForCsvV2 } from './fromatCompartmentForCsv';

export default function OperationDetailDialog(props) {
  const { t } = useTranslation();
  const { operation, setOpen, open } = props;
  
  const handleClose = () => {
    setOpen(false);
  };

  const getCompartmentCsv = () => {
    const csv = operation.form?.version === 2 ? 
      formatCompartmentForCsvV2(operation, t) 
      : formatCompartmentForCsv(operation, t);

    const operationCsv = Papa.unparse(
      [csv],
      {
        delimiter: ';',
      },
    );
    return operationCsv;

  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('tank_selection.tank')}
        {': '}
        {operation.tankName || operation.tankNumber}
      </DialogTitle>
      <DialogContent>
        {operation.form?.version === 2 ? (
          <OperationDetailsV2 operation={operation} />
        ) : (
          <>
            <OperationDetails operation={operation} />
            {operation.volumeConversionResults
              && (<OperationVolumeConversion batchResults={operation.volumeConversionResults} />)}
          </>
        )}
        
        {operation.volumeConversionMethod
        && (
          <Typography component="span">
            {t('settings.volume_calculation_method')}
            {': '}
            {operation.volumeConversionMethod === 'traditional'
         && (t('settings.traditional_method'))}
            {operation.volumeConversionMethod !== 'traditional'
         && ('OIML-OIV')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            downloadCsvFile('compartiment', getCompartmentCsv());
          }}
        >
          {t('download')}
        </Button>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
