import React, { useEffect, useReducer, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {
  doc, getDoc,
  disableNetwork,
  enableNetwork,
  addDoc,
  collection,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { MenuProvider } from 'menu-actions/MenuContext';
import {
  BrowserRouter, Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import StoragePDFViewerPage from 'components/StoragePDFViewerPage';
import MyAccount from 'components/account/MyAccount';
import Admin from 'components/admin/Admin';
import cgu from 'staticFiles/CGU.pdf';
import cgv from 'staticFiles/CGV.pdf';
import cgucgv from 'staticFiles/CGU+CGV.pdf';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import GlobalSnackbar from 'components/GlobalSnackbar';
import { Cookies, Contact } from 'labox-ws-commons';
import { getAnalytics } from 'firebase/analytics';
import RemoteConfigStatus from 'components/RemoteConfigStatus';
import privacyFR from 'staticFiles/privacy-policy-fr.pdf';
import privacyEN from 'staticFiles/privacy-policy-en.pdf';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import SignInPage from './SignInPage';
import TankSelection from './tankSelection/TankSelection';
import Operation from './operation/Operation';
import TopButtons from './TopButtons';
import Footer from './Footer';
import PDFView from './PDFView';
import OnboardingPage from './onboarding/OnboardingPage';
import ScrollToTop from './ScrollToTop';
import LoadingPage from './LoadingPage';
import NotFound from './NotFound';

export default function Home() {
  return <RemoteConfigStatus defaultComponent={<UserSetupMenuAndMainPage />} />;
}

function UserSetupMenuAndMainPage() {
  const { currentUser: user, initializing } = useAuth();
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    // eslint-disable-next-line no-promise-executor-return
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    async function getUser(uid) {
      const userRef = doc(db, 'users', uid);
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function getUserPrivateData(uid) {
      const userRef = doc(db, 'users', uid, 'private', 'data');
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function fetchMultipleTimesUser(uid) {
      let firestoreUser = await getUser(uid);
      if (!firestoreUser.exists()) {
        await sleep(1000);
        firestoreUser = await getUser(uid);
        if (!firestoreUser.exists()) {
          await await sleep(2000);
          firestoreUser = await getUser(uid);
        }
      }
      return firestoreUser;
    }

    async function setupGlobalStateFromUser() {
      globalActions.setUserStatus('loading');
      const firestoreUser = await fetchMultipleTimesUser(user.uid);
      const userData = firestoreUser.data();
      if (userData) {
        if (userData.laboxWSRoles) {
          globalActions.setUserWSRoles(userData.laboxWSRoles);
        }
      }
      const firestoreUserPrivateData = await getUserPrivateData(user.uid);
      const userPrivateData = firestoreUserPrivateData.data();
      globalActions.setActiveOrganization(userPrivateData.activeOrganization);

      if (userPrivateData.activeOrganization === '') {
        return globalActions.setUserStatus('notYetSetup');
      }
      const orgaAppPath = `/organizations/${userPrivateData.activeOrganization}/apps/digitank-gauged-containers`;
      const organizationAppRef = doc(db, orgaAppPath);
      const firestoreorganizationApp = await getDoc(organizationAppRef);
      const organizationAppData = firestoreorganizationApp.data();
      globalActions.setClientPath(orgaAppPath);
      const organizationPlan = organizationAppData.plan;
      globalActions.setUserPlan(organizationPlan);
      if (organizationAppData.admins.includes(user.uid)) {
        globalActions.setUserRoles(['admin']);
      }
      if (organizationAppData.addTanksButtonAvailable !== undefined) {
        globalActions.setAddTanksButtonAvailable(organizationAppData.addTanksButtonAvailable);
      }
      if (organizationAppData.gaugerCollaborations !== undefined) {
        globalActions.setGaugerCollaborations(organizationAppData.gaugerCollaborations);
      }
      if (organizationAppData.extraOperationFields !== undefined) {
        globalActions.setExtraOperationFields(organizationAppData.extraOperationFields);
      }
      if (organizationAppData.extraOperationFields !== undefined) {
        globalActions.setExtraOperationFields(organizationAppData.extraOperationFields);
      }
      if (organizationAppData.units !== undefined) {
        globalActions.setOrgUnits(organizationAppData.units);
      }
      return globalActions.setUserStatus('setupDone');
    }

    function setupFirestoreNetwork() {
      if (globalState.networkState === 'offline') {
        // console.log('FS desable network at setup');
        disableNetwork(db);
      }
    }

    if (user?.uid !== undefined && globalState.userStatus === 'initial') {
      setupGlobalStateFromUser();
      setupFirestoreNetwork();
    }
  }, [db, globalActions, globalState.networkState, globalState.userStatus, user?.uid]);

  window.ononline = (event) => {
    enableNetwork(db);
    globalActions.setNetworkState('online');
  };

  window.onoffline = (event) => {
    disableNetwork(db);
    globalActions.setNetworkState('offline');
  };

  if (initializing) {
    return <LoadingPage />;
  }

  return <MenuProvider app={<MainPage />} />;
}

function MainPage() {
  const [menuActions, dispatchMenuActions] = useReducer(reducerMenuActions, {});
  const [globalState] = useGlobal();
  const [displayAll, setdisplayAll] = useState(false);
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  function reducerMenuActions(currentState, dispatch) {
    let finalMenuActions;
    switch (dispatch.type) {
    case 'add':
      finalMenuActions = { ...currentState };
      dispatch.actions.forEach((action) => {
        finalMenuActions[action.name] = action.callback;
      });
      return finalMenuActions;
    case 'set':
      finalMenuActions = { };
      dispatch.actions.forEach((action) => {
        finalMenuActions[action.name] = action.callback;
      });
      return finalMenuActions;
    case 'clear':
      return {};
    default:
      throw new Error();
    }
  }

  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {globalState.networkState === 'offline' && (
          <Alert
            sx={{
              padding: 1, mt: 1, justifyContent: 'center',
            }}
            severity="error"
            onClick={() => setdisplayAll(!displayAll)}
          >
            {t('offline')}
            <Button sx={{ ml: 1, height: 17 }} color="inherit" variant="outlined" size="small">
              {expandIcon()}
            </Button>
            <Collapse
              in={displayAll}
              sx={{
                width: '100%',
              }}
            >
              {t('offline_more')}
            </Collapse>
          </Alert>
        )}
        <Box sx={{
          minHeight: '80vh', display: 'flex', flexDirection: 'column',
        }}
        >
          <GlobalSnackbar />
          <TopButtons menuActions={menuActions} />
          <MainPageContent dispatchMenuActions={dispatchMenuActions} />
        </Box>
        <Footer />
        <Cookies
          cookieName="agreed-to-cookies-on-digitank-gauged-containers"
          t={t}
          globalActions={globalActions}
        />
      </Box>
    </BrowserRouter>
  );
}

function MainPageContent(props) {
  const { currentUser } = useAuth();
  const { dispatchMenuActions } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const db = useFirestore();
  const analytics = getAnalytics();

  useEffect(() => {
    if (!currentUser
      && location.pathname !== '/contact'
      && location.pathname !== '/cgu'
      && location.pathname !== '/cgv'
      && location.pathname !== '/cgu-cgv'
      && location.pathname !== '/privacy-policy'
      && location.pathname !== '/onboarding') {
      navigate('/signin');
    }
  }, [location.pathname, navigate, currentUser]);

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom et entreprise: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[Contenants Jaugés, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  function privacyPolicy() {
    if (t('locale') === 'fr') {
      return privacyFR;
    }
    return privacyEN;
  }

  return (
    <Routes>
      <Route path="/" element={<TankSelectionOrLoading />} />
      <Route path="operation/*" element={<Operation dispatchMenuActions={dispatchMenuActions} />} />
      <Route
        path="pdfViewer/*"
        element={<StoragePDFViewerPage dispatchMenuActions={dispatchMenuActions} />}
      />
      <Route exact path="account" element={<MyAccount />} />
      <Route exact path="signin" element={<SignInPage />} />
      <Route exact path="onboarding" element={<OnboardingPage />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={currentUser}
          />
        )}
      />
      <Route path="cgu" element={<PDFView path={cgu} />} />
      <Route path="cgv" element={<PDFView path={cgv} />} />
      <Route path="privacy-policy" element={<PDFView path={privacyPolicy()} />} />
      <Route path="cgu-cgv" element={<PDFView path={cgucgv} />} />
      <Route path="admin/*" element={<Admin />} />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}

function TankSelectionOrLoading() {
  const [globalState] = useGlobal();
  if (globalState.userStatus === 'initial') {
    <CircularProgress />;
  }
  return (
    <TankSelection />
  );
}
