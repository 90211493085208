import React, { useState } from 'react';
import {
  Box, Button, Step, StepButton, Stepper, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateAccountStep from './createAccount/CreateAccountStep';
import CreateOrganizationStep from './createOrganization/CreateOrganizationStep';
import PayAsYouGoStep from './payAsYouGo/PayAsYouGoStep';

export default function OnboardingPage({ tankListData, isOfflineAvailable }) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const steps = ['Create Account', 'Create Organization', 'Create Subscripton'];
  const stepComponents = [
    <CreateAccountStep key="create-account" />,
    <CreateOrganizationStep key="create-organization" />,
    <PayAsYouGoStep key="pay-as-you-go" />
  ];

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    if (completed[activeStep]) {
      const newActiveStep = isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if (completed[step] || step < activeStep) {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            {stepComponents[activeStep] && React.cloneElement(stepComponents[activeStep], { handleComplete })}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t('navigation.previous_page')}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                disabled={!completed[activeStep]}
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                {t('navigation.next_page')}
              </Button>
            </Box>
          </>
        )}
      </div>
    </Box>
  );
}
