import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { Container } from '@mui/material';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import CssBaseline from '@mui/material/CssBaseline';
import LoadingPage from 'components/LoadingPage';
import { initializeApp } from 'firebase/app';
import { AuthProvider } from 'firebaseHooks/AuthContext';
import { AnalyticsProvider } from 'firebaseHooks/AnalyticsContext';
import { FirestoreProvider } from 'firebaseHooks/FirestoreContext';
import { FunctionsProvider } from 'firebaseHooks/FunctionsContext';
import { StorageProvider } from 'firebaseHooks/StorageContext';
import { RemoteConfigProvider } from 'firebaseHooks/RemoteConfigContext';
import Home from './components/Home';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'gc.digitank.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:ff8426104f36f014297452',
  measurementId: 'G-ETQTB6CESB',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#3f4d5a',
    },
    info: {
      main: '#437A89',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    flashyGreen: {
      main: '#1db954',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
    white: {
      main: '#ffffff',
      contrastText: 'black',
    },
  },
});

function App() {
  const app = initializeApp(firebaseConfig);

  return (
    <div className="App" style={{ whiteSpace: 'pre-line' }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container sx={{px: 1}}>
            <CssBaseline />
            <AuthProvider app={app}>
              <RemoteConfigProvider app={app}>
                <AnalyticsProvider app={app}>
                  <FirestoreProvider app={app}>
                    <FunctionsProvider app={app}>
                      <StorageProvider app={app}>
                        <Core app={app} />
                      </StorageProvider>
                    </FunctionsProvider>
                  </FirestoreProvider>
                </AnalyticsProvider>
              </RemoteConfigProvider>
            </AuthProvider>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

function Core({ app }) {
  const [status, setStatus] = useState('initial');
  const auth = getAuth(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);

  const initializeFirebase = useCallback(() => {
    setStatus('loading');
    if (process.env.NODE_ENV === 'development') {
      connectAuthEmulator(auth, 'http://localhost:9099');
      connectFunctionsEmulator(functions, 'localhost', 5001);
      connectStorageEmulator(storage, 'localhost', 9199);
    }

    setStatus('loaded');
  }, [auth, functions, storage]);

  useEffect(() => {
    if (status === 'initial') {
      initializeFirebase();
    }
  }, [initializeFirebase, status]);

  if (status !== 'loaded') {
    return (
      <LoadingPage />
    );
  }

  return <Home />;
}

export default App;
