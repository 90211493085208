export function formatCompartmentForCsv(op, t) {
  return {
    operationId: op.id,
    [t('operation_csv_headers.tankNumber')]: op.tankName || op.tankNumber,
    [t('operation_csv_headers.height')]: op.form.fields.height.value,
    [t('operation_csv_headers.volume', { unit: op.form.volumeUnit || 'HL' })]: op.form.fields.volume.value,
    [t('operation_csv_headers.volumeChange', { unit: op.form.volumeUnit || 'HL' })]: `${op.form.volumeChange
        === 'add' ? '+' : '-'}${op.form.volumeToAdd.value}`,
    [t('operation_csv_headers.finalVolume', { unit: op.form.volumeUnit || 'HL' })]: op.form.results.volume,
    [t('operation_csv_headers.finalHeight')]: op.form.results.height,
    date: new Date(op.dateAndTime.toMillis()).toLocaleString(),
    [t('operation_csv_headers.cellar')]: op.cellarName || op.cellar,
    [t('operation_csv_headers.certificateNumber')]: op.certificateName || op.certificateNumber,
  };
}

export function formatCompartmentForCsvV2(op, t) {
  return {
    operationId: op.id,
    [t('operation_csv_headers.tankNumber')]: op.tankName || op.tankNumber,
    [t('operation_csv_headers.height')]: op.form.height.value,
    [t('operation_csv_headers.volume', { unit: op.form.volume.unit || 'HL' })]: op.form.volume.value,
    [t('operation_csv_headers.volumeChange', { unit: op.form.volumeToAdd.unit || 'HL' })]: 
        `${op.form.volumeToAdd.isAddVolume ? '+' : '-'}${op.form.volumeToAdd.value}`,
    [t('operation_csv_headers.finalVolume', { unit: op.form.results.volume.unit || 'HL' })]: op.form.results.volume.value,
    [t('operation_csv_headers.finalHeight')]: op.form.results.height.value,
    date: new Date(op.dateAndTime.toMillis()).toLocaleString(),
    [t('operation_csv_headers.cellar')]: op.cellarName || op.cellar,
    [t('operation_csv_headers.certificateNumber')]: op.certificateName || op.certificateNumber,
  };
}