// store.ts
import React from 'react';
import { navigatorNetworkState } from 'components/utils';
import useGlobalHook from './useGlobalHook';
import * as actions from './actions';

interface GlobalSnackbar {
  display: boolean;
  message: string;
  severity: 'info' | 'warning' | 'error' | 'success';
}

interface OrgUnits {
  volume: string;
}

export interface GlobalState {
  cookiesAccepted: boolean;
  clientPath: string;
  networkState: string;
  userStatus: string;
  userPlan: string;
  globalSnackbar: GlobalSnackbar;
  roles: string[];
  wsRoles: string[];
  activeOrganization: string;
  addTanksButtonAvailable: boolean;
  extraOperationFields: any[];
  gaugerCollaborations: any[];
  orgUnits: OrgUnits;
  onboardingGaugerOrganizationId: string;
}

const initialState: GlobalState = {
  cookiesAccepted: false,
  clientPath: '',
  networkState: navigatorNetworkState(),
  userStatus: 'initial',
  userPlan: '',
  globalSnackbar: { display: false, message: '', severity: 'info' },
  roles: [],
  wsRoles: [],
  activeOrganization: '',
  addTanksButtonAvailable: false,
  extraOperationFields: [],
  gaugerCollaborations: [],
  orgUnits: {
    volume: 'hL',
  },
  onboardingGaugerOrganizationId: 'Y9FZuGEJnYvsVXKJ3OX1', // This is in prod
  // onboardingGaugerOrganizationId: 'rjTZVS7EJEOqiyibjs6a', // This is in emulator
};


// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;