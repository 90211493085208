// actions.ts
import { GlobalState } from './store';

interface Store {
  setState: (newState: Partial<GlobalState>) => void;
}

export const setCookiesAccepted = (store: Store, value: boolean): void => {
  store.setState({ cookiesAccepted: value });
};

export const setUserPlan = (store: Store, value: string): void => {
  store.setState({ userPlan: value });
};

export const setClientPath = (store: Store, path: string): void => {
  store.setState({ clientPath: path });
};

export const setNetworkState = (store: Store, state: string): void => {
  store.setState({ networkState: state });
};

interface SnackbarOptions {
  message: string;
  severity?: 'info' | 'warning' | 'error' | 'success';
}

export const setSnackbarMessage = (store: Store, options: SnackbarOptions = { message: '', severity: 'info' }): void => {
  store.setState({
    globalSnackbar: {
      display: true,
      message: options.message,
      severity: options.severity || 'info',
    },
  });
};

export const closeSnackbar = (store: Store): void => {
  store.setState({ globalSnackbar: { display: false, message: '', severity: 'info' } });
};

export const setUserRoles = (store: Store, roles: string[]): void => {
  store.setState({ roles: roles });
};

export const setUserWSRoles = (store: Store, wsRoles: string[]): void => {
  store.setState({ wsRoles: wsRoles });
};

export const setUserStatus = (store: Store, status: string): void => {
  store.setState({ userStatus: status });
};

export const setActiveOrganization = (store: Store, organization: string): void => {
  store.setState({ activeOrganization: organization });
};

export const setAddTanksButtonAvailable = (store: Store, value: boolean): void => {
  store.setState({ addTanksButtonAvailable: value });
};

export const setGaugerCollaborations = (store: Store, collaborations: any[]): void => {
  store.setState({ gaugerCollaborations: collaborations });
};

export const setExtraOperationFields = (store: Store, fields: any[]): void => {
  store.setState({ extraOperationFields: fields });
};

export const setOrgUnits = (store: Store, orgUnits: { volume: string }): void => {
  store.setState({ orgUnits: orgUnits });
};

export interface GlobalActions {
  setCookiesAccepted: (value: boolean) => void;
  setUserPlan: (value: string) => void;
  setClientPath: (path: string) => void;
  setNetworkState: (state: string) => void;
  setSnackbarMessage: (options: SnackbarOptions) => void;
  closeSnackbar: () => void;
  setUserRoles: (roles: string[]) => void;
  setUserWSRoles: (wsRoles: string[]) => void;
  setUserStatus: (status: string) => void;
  setActiveOrganization: (organization: string) => void;
  setAddTanksButtonAvailable: (value: boolean) => void;
  setGaugerCollaborations: (collaborations: any[]) => void;
  setExtraOperationFields: (fields: any[]) => void;
  setOrgUnits: (orgUnits: { volume: string }) => void;
}