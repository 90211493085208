import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';
import AcceptGTCU from './AcceptGTCU';

export default function CreateAccountForm(props) {
  const {
    t, navigate, Link, auth, analytics, gtcuCookieName, globalActions, afterSignInRedirect,
    createUserWithEmailAndPassword, sendEmailVerification, logEvent, signinCallback = () => {},
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const cookies = new Cookies();
  const [acceptedGTCU, setacceptedGTCU] = useState(false);
  const [showGTCUError, setshowGTCUError] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  async function createAccount(email, password, setSubmitting) {
    if (!acceptedGTCU) {
      return setshowGTCUError(true);
    }
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);
      if (user != null) {
        sendEmailVerification(
          user.user,
          { url: `${window.location.protocol}//${window.location.host}/account?confirm_email=true` },
        );
      }
      signinCallback();
      if (afterSignInRedirect) {
        navigate(afterSignInRedirect);
      }
    } catch (error) {
      globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
      logEvent(analytics, 'create_account_error', {
        error_message: error.message,
      });
    } finally {
      setSubmitting(false);
    }
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >

      <Formik
        initialValues={{ email: '', password: '', password2: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = t('validations.required');
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = t('validations.email_invalid');
          }
          if (!values.password) {
            errors.password = t('validations.required');
          }
          if (values.password.length < 6) {
            errors.password = t('signin.six_char_error');
          }
          if (!values.password2) {
            errors.password2 = t('validations.required');
          }
          if (values.password2 !== values.password) {
            errors.password2 = t('signin.same_password_error');
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // storing on both localStorage(stays longer) and cookies (sent to server) just in case
          localStorage.setItem(gtcuCookieName, 'true');
          cookies.set(gtcuCookieName, 'true');
          await createAccount(values.email, values.password, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                width: '100%',
              }}
            >
              <TextField
                style={{ maxWidth: 300 }}
                fullWidth
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label={t('signin.email')}
                error={touched.email && Boolean(errors.email)}
                helperText={errors.email && touched.email && errors.email}
              />
              <TextField
                style={{ maxWidth: 300 }}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                name="password"
                error={touched.password && Boolean(errors.password)}
                helperText={
                  errors.password && touched.password && errors.password
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label={t('signin.password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                style={{ maxWidth: 300 }}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                name="password2"
                error={touched.password2 && Boolean(errors.password2)}
                helperText={
                  errors.password2 && touched.password2 && errors.password2
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password2}
                label={t('signin.confirm_password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <AcceptGTCU
                acceptedGTCU={acceptedGTCU}
                setacceptedGTCU={setacceptedGTCU}
                showGTCUError={showGTCUError}
                setshowGTCUError={setshowGTCUError}
                t={t}
                Link={Link}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {t('signin.create_account_short')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
