import React from 'react';
import { Box, Dialog, 
  DialogContent, AppBar, Toolbar, IconButton, 
  DialogTitle, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AlcoholFormTabs from '../ap-vol-conversion/AlcoholFormTabs';
import { useFormik } from 'formik';
import validationSchema from '../ap-vol-conversion/ValidationSchema';
import decimalsMask from './DecimalsMask';
import CloseIcon from '@mui/icons-material/Close';
import { LabelInput, NumberInput } from '../ap-vol-conversion/BoxetteInputs';
import { PAConvParams } from './AddOrRemoveTabs';
import { FormInputs } from './CompartmentValidationSchema';
import { UseFormReturn } from 'react-hook-form';

interface PAConvFormValues {
  isElectronicDensimeter: boolean;
  measuredTav: string;
  tavTemperature: string;
  liquidTemperature: string;
}

interface TAVInputsDialogProps {
  paConvInputs: PAConvParams;
  rhform: UseFormReturn<FormInputs, any, undefined>;
  handleClose: () => void;
  apDialogOpen: boolean;
}

const TAVInputsDialog: React.FC<TAVInputsDialogProps> = ({ 
  rhform, paConvInputs, handleClose, apDialogOpen }) => {
  const { t } = useTranslation();

  const { setValue } = rhform;

  return (
    <Dialog 
      open={apDialogOpen} onClose={handleClose} 
      fullScreen
      disableEscapeKeyDown>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => 
            {
              setValue('volumeToAdd.isPureAlcohol', false);
              handleClose()
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogTitle>{t('ap_calculation_title')}</DialogTitle>
      <DialogContent sx={{ width: '100%', display:'flex', 
        flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        <PAToVolInputForm 
          paConvInputs={paConvInputs} 
          rhform={rhform} 
          handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

interface PAToVolInputFormProps {
    paConvInputs: PAConvParams;
    rhform: UseFormReturn<FormInputs, any, undefined>;
    handleClose: () => void;
}


const PAToVolInputForm: React.FC<PAToVolInputFormProps> = ({ 
  paConvInputs, rhform, handleClose }) => {
  const { t } = useTranslation();

  const { setValue } = rhform;
  
  const submit = async (values: PAConvFormValues) => {
    handleClose();
    setValue( "tavInputs" , {
      isElectronicDensimeter: values.isElectronicDensimeter,
      measuredTav: {
        value: values.measuredTav,
        unit: 'TAV',
        precision: decimalsMask.TAV,
      },
      tavTemperature: {
        value: values.tavTemperature,
        unit: 'celsius',
        precision: decimalsMask.celsius,
      },
      liquidTemperature: {
        value: values.liquidTemperature,
        unit: 'celsius',
        precision: decimalsMask.celsius,
      },
    });
  };

  function initTab() {
    const savedTab = localStorage.getItem('last_alco_conversion_tab');
    if (!savedTab) {
      return 'tav';
    }
    return savedTab;
  }
  
  const formik = useFormik({
    initialValues: {
      isElectronicDensimeter: initTab() !== 'tav',
      measuredTav: paConvInputs.measuredTav?.value || '',
      tavTemperature: paConvInputs.tavTemperature?.value || '',
      liquidTemperature: paConvInputs.liquidTemperature?.value || '',
    },
    validationSchema: validationSchema(t),
    onSubmit: submit,
  });
  
  return (
    <>
      <AlcoholFormTabs formik={formik} />
      <Box sx={{ width: '100%', maxWidth: 400, mb: 2}}>
        <LabelInput
          label={
            <Typography component="span">{t('temperatureInValue')}</Typography>
          }
          input={(
            <NumberInput
              onChange={formik.handleChange}
              name="liquidTemperature"
              placeholder="ex: 24"
              value={formik.values.liquidTemperature}
              decimalScale={decimalsMask.celsius}
              onBlur={formik.handleBlur}
              allowNegative
            />
          )}
          error={formik.touched.liquidTemperature && formik.errors.liquidTemperature && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.liquidTemperature}
            </Typography>
          )}
          select={(
            <Typography>
        % vol.
            </Typography>
          )}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => formik.handleSubmit()}>
        {t('operation.done')}
      </Button>
    </>
  );
};

export default TAVInputsDialog;