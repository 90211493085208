import React from 'react';
import Typography from '@mui/material/Typography';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import { useTranslation } from 'react-i18next';
import { PAConvParams } from './AddOrRemoveTabs';
import { Box } from '@mui/material';

interface DisplayPAInputsProps {
  paInputs: PAConvParams;
}

const DisplayPAInputs: React.FC<DisplayPAInputsProps> = ({ paInputs }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, 
      alignItems: 'flex-start', justifyContent: 'center', 
      gap: {xs: 1, sm: 2, md: 3}, p: 1 }}>
      <Typography component="span">
        {paInputs.measuredTav && paInputs.tavTemperature ? t('abv_at_temp_res', {
          temp: paInputs.tavTemperature.value,
          value: paInputs.measuredTav.value,
          unit: dynamicUnitName(paInputs.measuredTav.unit),
        }) : ''}
      </Typography>
    
      <Typography component="span">
        {t('liquid_temp_res', {
          value: paInputs.liquidTemperature.value,
          unit: dynamicUnitName(paInputs.liquidTemperature.unit),
        })}
      </Typography>
    </Box>
  );
};

export default DisplayPAInputs;