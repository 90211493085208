import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import { useTranslation } from 'react-i18next';
import { PAResults, TAVInputs } from '../compartment/CompartmentValidationSchema';

interface DisplayResultsProps {
  apResult: PAResults;
  tavInputs: TAVInputs;
}

const DisplayResults: React.FC<DisplayResultsProps> = ({ apResult, tavInputs }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} lg={4}>
        <Typography component="span">
          {tavInputs.measuredTav && tavInputs.tavTemperature ? t('abv_at_temp_res', {
            temp: tavInputs.tavTemperature.value,
            value: tavInputs.measuredTav.value,
            unit: dynamicUnitName(tavInputs.measuredTav.unit),
          }) : ''}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Typography component="span">
          {t('liquid_temp_res', {
            value: tavInputs.liquidTemperature.value,
            unit: dynamicUnitName(tavInputs.liquidTemperature.unit),
          })}
        </Typography>
      </Grid>
      { !/^20(\.0{1,2})?$/.test(String(tavInputs.tavTemperature?.value)) &&
        <Grid item xs={12} md={6} lg={4}>
          <Typography component="span">
            {apResult.abvAt20c ? t('abv_20c_res', {
              value: apResult.abvAt20c.value,
              unit: dynamicUnitName(apResult.abvAt20c.unit),
            }) : ''}
          </Typography>
        </Grid>
      }
      <Grid item xs={12} md={6} lg={4}>
        <Typography component="span">
          {apResult.volumeAtTemp ? t('volume_at_temp_res', {
            tempValue: apResult.volumeAtTemp.temperature.value,
            tempUnit: dynamicUnitName(apResult.volumeAtTemp.temperature.unit),
            value: apResult.volumeAtTemp.value,
            unit: dynamicUnitName(apResult.volumeAtTemp.unit),
          }) : ''}
        </Typography>
      </Grid>
      { !/^20(\.0{1,2})?$/.test(String(apResult.volumeAtTemp?.temperature?.value)) 
      && apResult.volumeAt20c 
      && <Grid item xs={12} md={6} lg={4}>
        <Typography component="span">
          {apResult.volumeAt20c ? t('volume_at_temp_res', {
            tempValue: apResult.volumeAt20c.temperature.value,
            tempUnit: dynamicUnitName(apResult.volumeAt20c.temperature.unit),
            value: apResult.volumeAt20c.value,
            unit: dynamicUnitName(apResult.volumeAt20c.unit),
          }) : ''}
        </Typography>
      </Grid>
      }
      <Grid item xs={12} md={6} lg={4}>
        <Typography component="span">
          {apResult.pureAlcoholVolumeAt20c ? t('pure_alcohol_res', {
            value: apResult.pureAlcoholVolumeAt20c.value,
            unit: dynamicUnitName(apResult.pureAlcoholVolumeAt20c.unit),
          }) : ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DisplayResults;