import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { nominalVolumeFromCompartment } from 'components/utils';
import DisplayResults from 'components/operation/ap-vol-conversion/DisplayResults';

export default function OperationDetailsV2(props) {
  const { operation } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 3, width: '100%',
      }}
    >
      <Typography variant="body2">
        {t('tank_selection.cellar')}
        {' : '}
        {operation.cellarName || operation.cellar}
        <br />
        {t('tank_selection.certificate')}
        {' : '}
        {operation.certificateName || operation.certificateNumber}
        <br />
        <br />
        {t('tank_selection.operation_date')}
        {' : '}
        {new Date(operation.dateAndTime.toMillis()).toLocaleString()}
        <br />
        ID operation:
        {' '}
        {operation.id}
        <br />
        {t('admin.operator')}
        {' : '}
        {operation.userEmail}
      </Typography>
      <CompartmentOperation operation={operation} />
    </Box>
  );
}

function CompartmentOperation(props) {
  const { t } = useTranslation();
  const { operation } = props;

  const paConversionResults = operation.form.paConversionResults;

  return (
    <Box
      sx={{
        display: 'flex', gap: 3, flexDirection: 'column', width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', gap: 0, flexDirection: 'row' }}>
        <Typography
          sx={{ width: '50%' }}
          variant="subtitle2"
          gutterBottom
          component="div"
        >
          {t('operation.nominal_volume', { unit: 'HL' })}
          {' : '}
          {nominalVolumeFromCompartment(operation.form, t)}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
      }}
      >
        <Box sx={{ flexGrow: 1 }}>{`${t('operation.height')} : ${operation.form.height.value}`}</Box>
        <Box sx={{ flexGrow: 1 }}>
          {`${t(
            'operation.volume',
            { unit: operation.form.volume.unit || 'HL' },
          )} : ${operation.form.volume.value}`}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {`Volume ${operation.form.volumeToAdd.isAddVolume ? t('added') : t('removed')} `}
          {operation.form.volumeToAdd.isPureAlcohol ? <strong>{t('ap')}</strong> : ''}
          {` (${operation.form.volume.unit || 'HL'})`}
          {` : ${operation.form.volumeToAdd.value}`}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {t('operation.final_volume')}
          {` (${operation.form.results.volume.unit || 'HL'}) : ${operation.form.results.volume.value}`}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {t('operation.final_height')}
          {` (mm) : ${operation.form.results.height.value}`}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      {paConversionResults && Object.keys(paConversionResults).length > 0 && (
        <DisplayResults
          apResult={paConversionResults}
          tavInputs={operation.form.tavInputs} />
      )}

      {operation.form.extraFields && operation.form.extraFields.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          {operation.form.extraFields.map((field) => (
            <Typography key={field.name} component="span">
              {`${field.display}: ${field.value}`}
            </Typography>
          ))}
        </Box>
      )}

    </Box>
  );
}
