import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';


interface NumberInputProps {
    stretch?: boolean;
    label: string;
    name: string;
    decimalScale: number;
    onChange?: (event: { target: { name: string; value: string } }) => void;
    onBlur?: () => void;
    inputcolor?: string;
    inputMode?: 'text' | 'tel' | 'email' | 'search' | 'url' | 'none' | 'numeric' | 'decimal';
    highlighted?: boolean;
    error?: boolean;
    helperText?: string;
    control: any;
  }

export default function CompartmentField(props: NumberInputProps) {
  const {
    stretch, label, name, decimalScale, onChange, control, onBlur,
    inputcolor, inputMode, highlighted, error, helperText,
  } = props;
  const inputWidth = stretch ? '100%' : 'auto';
  const regularStyle = {
    backgroundColor: inputcolor || '#fff',
    transition: 'box-shadow 0.15s linear',
    borderRadius: '4px',
    width: inputWidth,
  };
  const highlightedStyle = {
    ...regularStyle,
    boxShadow: 'inset 0px 0px 7px 0px #437a89',
  };
  const myInputMode = inputMode || 'decimal';

  const handleKeyPressed = (e: React.KeyboardEvent) => {
    const key = e.key ? e.key : e.code;
    if (['Enter', 'NumpadEnter'].includes(key)) {
      onBlur && onBlur();
    }
  };

  const handleBlur = (field: any) => {
    field.onBlur();
    onBlur && onBlur();
  }

  const handleChange = (field: any, values: any, sourceInfo: any) => {
    if (sourceInfo.source === 'event') {
      field.onChange(values.value);
      onChange && onChange({
        target: {
          name,
          value: values.value,
        },
      })
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <NumericFormat
          decimalScale={decimalScale}
          label={label}
          sx={highlighted ? highlightedStyle : regularStyle}
          className="TextFieldBackground"
          variant="outlined"
          autoComplete="off"
          valueIsNumericString
          allowNegative={false}
          allowedDecimalSeparators={['.', ',']}
          decimalSeparator="."
          type="text"
          inputProps={{ inputMode: myInputMode }}
          customInput={TextField}
          value={field.value}
          onBlur={() => {
            handleBlur(field);
          }}
          onKeyDown={handleKeyPressed}
          onValueChange={(values, sourceInfo) => {
            handleChange(field, values, sourceInfo);
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}
