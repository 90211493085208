import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { LabelInputSelect, NumberInput } from './BoxetteInputs';
import decimalsMask from '../compartment/DecimalsMask';

export default function AlcoConversionTAVForm({ formik }) {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_tav')}</Typography>
        }
        input={(
          <NumberInput
            onChange={formik.handleChange}
            name="measuredTav"
            placeholder="ex: 56.88"
            value={formik.values.measuredTav}
            decimalScale={decimalsMask.TAV}
            onBlur={formik.handleBlur}
          />
        )}
        error={formik.touched.measuredTav && formik.errors.measuredTav && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.measuredTav}
          </Typography>
        )}
        select={(
          <Typography>
            % vol.
          </Typography>
        )}
      />

      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_temp')}</Typography>
        }
        input={(
          <NumberInput
            name="tavTemperature"
            onChange={formik.handleChange}
            placeholder="ex: 12.2"
            value={formik.values.tavTemperature}
            decimalScale={decimalsMask.celsius}
            onBlur={formik.handleBlur}
            allowNegative
          />
        )}
        error={formik.touched.tavTemperature && formik.errors.tavTemperature && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.tavTemperature}
          </Typography>
        )}
        select={(
          <Typography>
            °C
          </Typography>
        )}
      />
    </form>
  );
}
