import React from 'react';
import {
  Typography,
  Link as MUILink,
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

export default function AcceptGTCU(props) {
  const {
    setacceptedGTCU, acceptedGTCU, showGTCUError, setshowGTCUError, t, Link,
  } = props;

  const handleCheck = () => {
    if (acceptedGTCU === false) {
      setshowGTCUError(false);
      setacceptedGTCU(!acceptedGTCU);
    }
    setacceptedGTCU(!acceptedGTCU);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Checkbox
          name="acceptedCGU"
          checked={acceptedGTCU}
          onChange={handleCheck}
        />
        <Typography component="span" sx={{ mg: 1 }}>
          {t('welcome.i_accept_gtcu')}
          {' '}
          <MUILink
            component={Link}
            to="/cgu"
            color="secondary"
          >
            <b>{t('welcome.gtcu')}</b>
          </MUILink>
        </Typography>
      </Box>
      {showGTCUError && (
        <Typography variant="caption" color="red">
          {t('validations.required_gtcu')}
        </Typography>
      )}
    </Box>
  );
}
