import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { unit } from 'mathjs';
import { roundNoFixed, nominalVolumeFromCompartment } from 'components/utils';
import useGlobal from 'global-state/store';
import ResultCard from 'components/ResultCard';
import useMenu from 'menu-actions/useMenu';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import validationSchema, { FormInputs } from './CompartmentValidationSchema';
import OperationButtons from './OperationButtons';
import loadLastOperation from './loadLastOperation';
import ExtraOperationFields from '../ExtraOperationFields';
import { initialFormValues, submit } from './utils';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import { httpsCallable } from 'firebase/functions';
import AddOrRemoveTabs from './AddOrRemoveTabs';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import CompartmentField from './CompartmentField';
import DisplayResults from '../ap-vol-conversion/DisplayResults';
import TAVInputsDialog from './TAVInputsDialog';
import { Button, CircularProgress, LinearProgress } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import decimalsMask from './DecimalsMask';

interface CompartmentProps {
  compartment: any;
  tankNumber: string;
  certificatePath: string;
  cellar: string;
  dispatchMenuActions: any;
  certificatePdfPath: string;
  tankName: string;
  cellarName: string;
  certificateName: string;
}

const Compartment: React.FC<CompartmentProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    compartment, tankNumber, certificatePath, cellar, dispatchMenuActions, certificatePdfPath,
    tankName, cellarName, certificateName,
  } = props;
  const [globalState, globalActions] = useGlobal();
  const volumeUnit = globalState.orgUnits.volume;
  const { setreturnAction } = useMenu();
  const [highlightment, setHighlightment] = useState({
    height: false,
    volume: false,
  });
  const [tavInputsDialogOpen, setTAVInputsDialogOpen ] = React.useState(false);
  const [loadingCount, setLoadingCount] = useState(0);

  const removeHighlight = (fieldname: string) => {
    setHighlightment({
      ...highlightment,
      [fieldname]: false,
    });
  };

  useEffect(() => {
    setreturnAction(() => () => navigate('/'));
  }, [navigate, setreturnAction]);

  const firestore = useFirestore();

  const functions = useFunctions();
  functions.region = 'europe-west1';
  const laboxAlcoholometryApiCall = httpsCallable(functions, 'laboxAlcoholometryApiCall2ndGen');

  const fieldUtils = useMemo(() => ({
    height: {
      unitLabel: 'mm',
      unitCompute: 'm',
      searchUnitLabel: volumeUnit,
      searchUnitCompute: 'L',
      conversion: compartment.volumeToHeight,
      arrayToSearch: Object.keys(compartment.volumeToHeight)
        .map((x) => Number(x))
        .sort((a, b) => a - b),
    },
    volume: {
      unitLabel: volumeUnit,
      unitCompute: 'L',
      searchUnitLabel: 'mm',
      searchUnitCompute: 'm',
      conversion: compartment.heightToVolume,
      arrayToSearch: Object.keys(compartment.heightToVolume)
        .map((x) => Number(x))
        .sort((a, b) => a - b),
    },
  }), [compartment.heightToVolume, compartment.volumeToHeight, volumeUnit]);

  const minAndMaxes = {
    minHeight: roundNoFixed(unit(compartment.minHeight, 'm').toNumber('mm'), 4),
    maxHeight: roundNoFixed(unit(compartment.maxHeight, 'm').toNumber('mm'), 4),
    minVolume: roundNoFixed(unit(compartment.minVolume, 'L').toNumber(volumeUnit), 4),
    maxVolume: roundNoFixed(unit(compartment.maxVolume, 'L').toNumber(volumeUnit), 4),
  };

  const schema = validationSchema(
    t,
    minAndMaxes.minHeight,
    minAndMaxes.maxHeight,
    minAndMaxes.minVolume,
    minAndMaxes.maxVolume,
  );

  const rhform = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialFormValues(volumeUnit, initaddOrRemoveTabValue),
  })

  const {
    getValues,
    setError,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
    reset,
  } = rhform;

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setLoadingCount(prevCount => prevCount + 1);
    try {
      await submit(data, setValue, setHighlightment, removeHighlight, fieldUtils, 
        globalState, globalActions, t, laboxAlcoholometryApiCall, minAndMaxes, setError);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoadingCount(prevCount => prevCount - 1);
    }
  }

  function initaddOrRemoveTabValue() {
    const savedTab = localStorage.getItem('last_operation_volume_to_add_tab');
    if (!savedTab) {
      return 'add';
    }
    return savedTab;
  }

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (type === 'change') {
        if (name === 'height.value' && data.height?.value) {
          setValue('volume.value', '');
        } else if (name === 'volume.value' && data.volume?.value) {
          setValue('height.value', '');
        }
      }
    });

    // Unsubscribe when the component unmounts
    return () => subscription.unsubscribe();
  }, [watch, setValue, fieldUtils.volume.arrayToSearch, fieldUtils.volume.conversion, 
    fieldUtils.volume.searchUnitLabel, fieldUtils.volume.searchUnitCompute, fieldUtils.volume.unitLabel, 
    fieldUtils.volume.unitCompute, fieldUtils.height.arrayToSearch, fieldUtils.height.conversion, 
    fieldUtils.height.searchUnitLabel, fieldUtils.height.searchUnitCompute, fieldUtils.height.unitLabel, 
    fieldUtils.height.unitCompute]);

  const callSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const finalVolume = watch('results.volume.value');
  const finalHeight = watch('results.height.value');
  const finalVolumeUnit = watch('results.volume.unit');
  const finalHeightUnit = watch('results.height.unit');
  const paConversionResults = watch('paConversionResults');
  const tavInputs = watch('tavInputs');
  const tavInputsPresent = tavInputs.measuredTav.value ? true : false;

  useEffect(() => {
    const loadLastOperationAction = {
      name: t('operation.load_last_operation'),
      callback: () => {
        loadLastOperation(
          tankNumber,
          globalState,
          globalActions,
          t,
          firestore,
          reset,
          initialFormValues(volumeUnit, initaddOrRemoveTabValue),
        );
      },
    };
    const handleGoToPDFViewer = () => {
      if (globalState.networkState === 'offline') {
        globalActions.setSnackbarMessage({ message: t('functionality_requires_connection'), severity: 'warning' });
      } else {
        navigate(`/pdfViewer?filePath=${encodeURIComponent(certificatePdfPath)}`);
      }
    };
    const goToCertifPdfAction = {
      name: t('storage.certificate_pdf'),
      callback: handleGoToPDFViewer,
    };
    dispatchMenuActions({ type: 'add', actions: [loadLastOperationAction, goToCertifPdfAction] });
  }, [certificatePdfPath, dispatchMenuActions, firestore, globalActions, globalState, navigate, 
    t, tankNumber, reset, volumeUnit]);

  
  const getFormToSave = () => {
    return {
      version: 2,
      nominalVolume: compartment.nominalVolume,
      volumeUnit,
      ...getValues(),
    }
  }
  
  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'stretch',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography
          sx={{ alignSelf: 'center' }}
          variant="subtitle2"
          gutterBottom
          component="div"
        >
          {t('operation.nominal_volume', { unit: 'HL' })}
          {': '}
          {nominalVolumeFromCompartment(compartment, t)}
        </Typography>
        <Paper
          elevation= {0}
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            pt: 3,
            px: 1,
            pb: 2,
            mb: 2,
          }}
        >
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}>
            <Box sx={{width: '100%'}}>
              <CompartmentField
                label={t('operation.height')}
                name="height.value"
                inputMode="numeric"
                decimalScale={0}
                highlighted={highlightment.height}
                control={control}
                onBlur={callSubmit}
                error={errors.height?.value?.message ? true : false}
                helperText={errors.height?.value?.message}
              />
            </Box>
            <Box sx={{width: '100%'}}>
              <CompartmentField
                label={t('operation.volume', { unit: volumeUnit })}
                name="volume.value"
                inputMode="decimal"
                decimalScale={decimalsMask[volumeUnit as keyof typeof decimalsMask]}
                highlighted={highlightment.volume}
                control={control}
                onBlur={callSubmit}
                error={errors.volume?.value?.message ? true : false}
                helperText={errors.volume?.value?.message}
              />
            </Box>
          </Box>
          <Button 
            sx={{mt: 2, mb: 1}}
            variant="outlined"
            color="primary">
            <DoneIcon sx={{fontSize: '18px' }} />
          </Button>
        </Paper>

        <AddOrRemoveTabs
          rhform={rhform}
          callSubmit={callSubmit}
          onTabChange={(event, newValue) => {
            setValue('volumeToAdd.isAddVolume', newValue === 'add');
            localStorage.setItem('last_operation_volume_to_add_tab', newValue);
            callSubmit();
          }}
          volumeUnit={volumeUnit}
          setTAVInputsDialogOpen={setTAVInputsDialogOpen}
        />

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexFlow: 'row wrap',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            mb: 3,
            mt: 3,
          }}
        >
          <ResultCard
            title={t('operation.final_volume')}
            result={loadingCount <= 0 ? `${finalVolume || '-'} ${finalVolumeUnit}` : <CircularProgress size="20px" />}
            maxWidth={340}
          />

          <ResultCard
            title={t('operation.final_height')}
            result={loadingCount <= 0 ? `${finalHeight || '-'} ${finalHeightUnit}` : <CircularProgress size="20px" />}
            maxWidth={340}
          />
        </Box>

        {tavInputsPresent && (
          <Paper
            elevation={0}
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              mt: 0,
              mb: 3,
              position: 'relative',
            }}
          >
            {loadingCount > 0 && (
              <LinearProgress
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                }}
              />
            )}
            <DisplayResults 
              apResult={paConversionResults}
              tavInputs={tavInputs} />
          </Paper>
        )}
        
        {!tavInputsPresent && (
          <Button
            size='small'
            variant="outlined"
            onClick={() => setTAVInputsDialogOpen(true)}
            sx={{alignSelf: 'center'}}
          >
            {t('calculate_ap')}
          </Button>
        )}
      
        <TAVInputsDialog 
          paConvInputs={tavInputs} 
          rhform={rhform}
          handleClose={() => {
            setTAVInputsDialogOpen(false);
            callSubmit();
          }}
          apDialogOpen={tavInputsDialogOpen} 
        />
        
        <ExtraOperationFields 
          setExtraFields={(fields: any) => setValue("extraFields", fields)} 
          tankId={tankNumber} />

        <OperationButtons
          isLoading={loadingCount > 0}
          getForm={getFormToSave}
          tankNumber={tankNumber}
          certificatePath={certificatePath}
          cellar={cellar}
          tankName={tankName}
          cellarName={cellarName}
          certificateName={certificateName}
        />
      </Box> 
    </Box>
  );
};

export default Compartment;