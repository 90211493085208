import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import SocialSigninCard from './SocialSignInCard';
import EmailPasswordLoginForm from './EmailPasswordLoginForm';
import PasswordForgottenCard from './PasswordForgottenCard';

export default function SignInContent(props) {
  const {
    t, globalActions, navigate, Link, auth,
    analytics, gtcuCookieName, ourSignInWithPopup, signinCallback,
    signInWithEmailAndPassword,
  } = props;
  const [emailPasswordCardType, setemailPasswordCardType] = useState('signin');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <SocialSigninCard
        t={t}
        analytics={analytics}
        navigate={navigate}
        Link={Link}
        auth={auth}
        gtcuCookieName={gtcuCookieName}
        ourSignInWithPopup={ourSignInWithPopup}
        signinCallback={signinCallback}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 3,
          px: 1,
          gap: 2,
          border: 1,
          borderRadius: 1,
          width: { xs: '100%', sm: '88%' },
          maxWidth: 670,
        }}
      >

        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {t('signin.email_password')}
        </Typography>

        {emailPasswordCardType === 'signin' && (
          <EmailPasswordLoginForm
            auth={auth}
            setemailPasswordCardType={setemailPasswordCardType}
            navigate={navigate}
            t={t}
            analytics={analytics}
            Link={Link}
            gtcuCookieName={gtcuCookieName}
            globalActions={globalActions}
            signInWithEmailAndPassword={signInWithEmailAndPassword}
            signinCallback={signinCallback}
          />

        )}

        {emailPasswordCardType === 'passwordForgotten' && (
          <PasswordForgottenCard
            setemailPasswordCardType={setemailPasswordCardType}
            t={t}
            auth={auth}
            analytics={analytics}
            Link={Link}
            globalActions={globalActions}
          />
        )}
      </Box>
    </Box>
  );
}
