import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  doc, getDoc,
} from 'firebase/firestore';
import { Button, CircularProgress, Paper } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useGlobal from 'global-state/store';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import CreateNewOrganization from './CreateNewOrganization';

export default function CreateOrganizationStep({ handleComplete }) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 3,
      width: '100%',
      mt: 4,
    }}
    >
      <ExistingOrganizations handleComplete={handleComplete} />
      <CreateNewOrganization handleComplete={handleComplete} />
    </Box>
  );
}

function ExistingOrganizations({ handleComplete }) {
  const { t } = useTranslation();
  const { currentUser: user } = useAuth();
  const [loadingState, setloadingState] = useState('initial');
  const db = useFirestore();
  const [existingOrganizations, setExistingOrganizations] = useState([]);
  const [orgaInvitations, setorgaInvitations] = useState([]);
  const [, globalActions] = useGlobal();

  useEffect(() => {
    async function loadUser() {
      const udocRef = doc(db, 'users', user.uid, 'private', 'data');
      const udocSnap = await getDoc(udocRef);
      setExistingOrganizations(udocSnap.data()?.organizations || []);
      setorgaInvitations(udocSnap.data()?.organizationInvitations || []);
      setloadingState('loaded');
    }
    if (loadingState === 'initial' && user?.uid !== undefined) {
      setloadingState('loading');
      loadUser();
    }
  }, [db, loadingState, user?.uid]);

  const handleSelectExisting = (orgId) => {
    globalActions.setActiveOrganization(orgId);
    handleComplete();
  };

  if (loadingState !== 'loaded') {
    return null;
  }

  if (existingOrganizations.length === 0) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        width: '100%',
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
        {t('onboarding.already_existing_orgs')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        {existingOrganizations.map((org) => (
          <Box
            key={org}
            elevation={0}
            sx={{
              padding: 1, border: 1, borderRadius: 1, cursor: 'pointer',
            }}
            onClick={() => handleSelectExisting(org)}
          >
            <ExistingOrgName orgId={org} />
          </Box>
        ))}
      </Box>
      {orgaInvitations.length > 0 && (
        <Typography component="span" sx={{ fontWeight: 'bold' }} variant="h6">
          {t('onboarding.org_invitations')}
        </Typography>
      )}
      {orgaInvitations.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'stretch',
            justifyContent: 'center',
            gap: 2,
            width: '100%',
          }}
        >
          {orgaInvitations.map((org) => (
            <Typography key={org}>{org}</Typography>
          ))}
        </Box>
      )}
      {(orgaInvitations.length > 0 || existingOrganizations.length > 0) && (
        <Button
          variant="outlined"
          href="https://orgs.labox-apps.com"
          color="primary"
          endIcon={<ArrowForwardIcon />}
        >
          {t('onboarding.to_orgs_management')}
        </Button>
      )}
    </Paper>
  );
}

function ExistingOrgName({ orgId }) {
  const [organization, setOrganization] = useState(undefined);
  const db = useFirestore();

  useEffect(() => {
    async function loadOrga() {
      const orgRef = doc(db, 'organizations', orgId);
      const orgSnap = await getDoc(orgRef);
      setOrganization(orgSnap.data());
    }
    if (organization === undefined) {
      loadOrga();
    }
  }, [db, orgId, organization]);

  if (!organization) {
    return <CircularProgress />;
  }

  return (
    <Typography>{organization.name}</Typography>
  );
}
