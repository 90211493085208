import * as React from 'react';
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { roundNoFixed } from 'components/utils';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import GauginCurve from './GauginCurve';

export default function GaugingDataValidation(props) {
  const { t } = useTranslation();
  const { data, tankId } = props;
  const [compartmentsDictionaries, setcompartmentsDictionaries] = React.useState([]);
  const [allHeights, setallHeights] = React.useState([]);

  React.useEffect(() => {
    const replaceComma = (value) => {
      if (value !== null) {
        return value.toString().replace(/,/, '.');
      }
      return null;
    };

    function numberOrNull(value) {
      if (value === undefined || value === '' || value === null) {
        return null;
      }
      const number = Number(replaceComma(value));
      if (isNaN(number)) {
        return null;
      }
      return number;
    }

    function toHL(v) {
      return roundNoFixed(v * 10, 3);
    }

    function toMM(v) {
      return roundNoFixed(v * 1000, 10);
    }

    const runParsing = () => {
      const parsedCsv = getParsedCsv(data);
      const headers = parsedCsv.meta.fields;
      const heightHeader = 'hauteur';
      const compartmentIds = headers.filter(
        (x) => x !== '' && x !== heightHeader,
      );
      const newCompartmentsDictionaries = compartmentIds.map((id) => ({
        id,
        minVolume: 9999999,
        minHeight: 9999999,
        maxVolume: 0,
        maxHeight: 0,
        heightToVolume: {},
      }));

      const newallHeights = [];

      for (const values of parsedCsv.data) {
        let height = numberOrNull(values[heightHeader]);
        height = toMM(height);
        newallHeights.push(height);
        for (const compartment of newCompartmentsDictionaries) {
          let compartmentVolume = numberOrNull(values[compartment.id]);
          if (compartmentVolume !== null) {
            compartmentVolume = toHL(compartmentVolume);
            if (compartmentVolume > compartment.maxVolume) {
              compartment.maxVolume = compartmentVolume;
            }
            if (compartmentVolume < compartment.minVolume) {
              compartment.minVolume = compartmentVolume;
            }
            if (height > compartment.maxHeight) {
              compartment.maxHeight = height;
            }
            if (height < compartment.minHeight) {
              compartment.minHeight = height;
            }
            compartment.heightToVolume[height] = compartmentVolume;
          }
        }
      }
      if (newCompartmentsDictionaries !== compartmentsDictionaries) {
        setcompartmentsDictionaries(newCompartmentsDictionaries);
        setallHeights(newallHeights);
      }
    };
    if (compartmentsDictionaries.length === 0) {
      runParsing();
    }
  }, [compartmentsDictionaries, data]);

  function getParsedCsv(csvData) {
    const csvText = csvData;
    const parsedCsv = Papa.parse(csvText, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimitersToGuess: [';', ','],
    });
    return parsedCsv;
  }

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 3, width: '100%',
      }}
    >
      <Typography variant="h6" component="span">
        {t('tank_creation.data_validation')}
        {': '}
        {tankId}
      </Typography>
      {compartmentsDictionaries.length > 0 && (
        <GauginCurve compartmentsDictionaries={compartmentsDictionaries} allHeights={allHeights} />)}
      <Typography variant="subtitle2" component="span">
        {t('tank_creation.volumes_min_and_max')}
      </Typography>
      <Box
        sx={{
          display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap', justifyContent: 'center',
        }}
      >
        {Object.values(compartmentsDictionaries).map((compartment) => (
          <div key={compartment.id}>
            <CompartmentMinMaxVolumes compartment={compartment} t={t} />
          </div>
        ))}
      </Box>
      <Typography variant="subtitle2" component="span">
        {t('tank_creation.heights_min_and_max')}
      </Typography>
      <Box
        sx={{
          display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center',
        }}
      >
        {Object.values(compartmentsDictionaries).map((compartment) => (
          <div key={compartment.id}>
            <CompartmentMinMaxHeights compartment={compartment} t={t} />
          </div>
        ))}
      </Box>

      <Typography variant="h6" component="span" sx={{ mt: 3 }}>
        {t('tank_creation.warning_continue')}
      </Typography>
    </Box>
  );
}

function CompartmentMinMaxVolumes(props) {
  const { compartment } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f2f5f8',
      }}
    >
      <Box sx={{
        display: 'flex-wrap', gap: 0, flexDirection: 'row',
      }}
      >
        <Typography>
          {' min: '}
          {(compartment.minVolume)}
        </Typography>
        <Typography>
          {' max: '}
          {(compartment.maxVolume)}
        </Typography>
      </Box>
    </Paper>
  );
}

function CompartmentMinMaxHeights(props) {
  const { compartment } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f2f5f8',
      }}
    >
      <Box sx={{
        display: 'flex-wrap', gap: 0, flexDirection: 'row',
      }}
      >
        <Typography>
          {' min: '}
          {(compartment.minHeight)}
        </Typography>
        <Typography>
          {' max: '}
          {(compartment.maxHeight)}
        </Typography>
      </Box>
    </Paper>
  );
}
