import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import decimalsMask from '../compartment/DecimalsMask';
import { LabelInputSelect, NumberInput } from './BoxetteInputs';

export default function AlcoConversionElecDensimeterForm({ formik }) {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <LabelInputSelect
        label={
          <Typography component="span">{t('abvAt20c')}</Typography>
        }
        input={(
          <NumberInput
            onChange={formik.handleChange}
            name="measuredTav"
            placeholder="ex: 56.88"
            value={formik.values.measuredTav}
            decimalScale={decimalsMask.TAV}
            onBlur={formik.handleBlur}
          />
        )}
        error={formik.touched.measuredTav && formik.errors.measuredTav && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.measuredTav}
          </Typography>
        )}
        select={(
          <Typography>
            % vol.
          </Typography>
        )}
      />
    </form>
  );
}
