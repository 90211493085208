import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import RuleIcon from '@mui/icons-material/Rule';
import BarrelIcon from 'icons/BarrelIcon';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Subscription from 'components/subscription/Subscription';
import useGlobal from 'global-state/store';
import SuperAdmins from 'components/superAdmin/SuperAdmin';
import TankCreation from './managingTanks/creation/TankCreation';
import Tanks from './managingTanks/Tanks';
import Cellars from './managingCellars/Cellars';
import Operations from './monitoringOperations/Operations';
import Validations from './monitoringValidations/Validations';
import CertificatesMonitoring from './CertificatesMonitoring';
import Notifications from './notifications/Notifications';
import GaugerCollaboration from './gaugerCollaboration/GaugerCollaboration';
import NotFound from 'components/NotFound';

export default function Admin() {
  return (
    <Routes>
      <Route path="operations" element={<Operations />} />
      <Route path="validations" element={<Validations />} />
      <Route path="subscription" element={<Subscription />} />
      <Route path="cellars" element={<Cellars />} />
      <Route path="tanks" element={<Tanks />} />
      <Route path="tanks/create" element={<TankCreation />} />
      <Route path="" element={<AdminReception />} />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}

function AdminReception() {
  const { t } = useTranslation();
  const [globalState] = useGlobal();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 3,
        px: { xs: 2, md: 10 },
        mt: { xs: 5, md: 10 },
        mb: 15,
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          width: '100%', maxWidth: 300, textAlign: 'left', mt: 3,
        }}
      >
        {`${t('admin.monitoring')} : `}
      </Typography>
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3, alignSelf: 'center',
        }}
      >
        <MenuButton
          to="/admin/operations"
          icon={<FormatListBulleted sx={{ fontSize: 150 }} />}
          text={t('admin.operations_management')}
        />
        <MenuButton
          to="/admin/validations"
          icon={<RuleIcon sx={{ fontSize: 150 }} />}
          text={t('admin.validations_management')}
        />
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{ width: '100%', maxWidth: 300, textAlign: 'left' }}
      >
        {`${t('admin.management')} : `}
      </Typography>
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3, alignSelf: 'center',
        }}
      >
        <MenuButton
          to="tanks"
          icon={<BarrelIcon size={150} color="#FFFFFF" />}
          text={t('admin.tanks_management')}
        />
        <MenuButton
          component="a"
          target="_blank"
          href="https://orgs-ws.labox-apps.com"
          icon={<PeopleAlt sx={{ fontSize: 150 }} />}
          text={t('admin.users_management')}
        />
        <MenuButton
          to="subscription"
          icon={<CreditCardIcon sx={{ fontSize: 150 }} />}
          text={t('subscription.name')}
        />
        {globalState.addTanksButtonAvailable === true && (
          <MenuButton
            to="cellars"
            icon={<WarehouseIcon sx={{ fontSize: 150 }} />}
            text={t('admin.cellars_management')}
          />
        )}
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{ width: '100%', maxWidth: 300, textAlign: 'left' }}
      >
        {`${t('other')} : `}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          alignItems: 'stretch',
          height: '100%',
          gap: 3,
          width: { xs: '100%', md: 800 },
          alignSelf: 'center',
        }}
      >
        <Box
          sx={{
            flexBasis: '50%',
            flexGrow: 1,
            width: ['100%', '50%'],
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <GaugerCollaboration />
          <Notifications />
        </Box>
        <Box
          sx={{
            flexBasis: '50%',
            flexGrow: 1,
            width: ['100%', '50%'],
            height: '100%',
          }}
        >
          <CertificatesMonitoring />
        </Box>
      </Box>
      <SuperAdmins />
    </Box>
  );
}

function MenuButton(props) {
  const {
    disabled, to, icon, text, href, component, target,
  } = props;

  return (
    <Button
      disabled={disabled}
      color="info"
      sx={{
        p: 2, gap: 2, display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: 300 },
      }}
      variant="contained"
      component={component || Link}
      href={href}
      target={target}
      to={to}
    >
      {icon}
      {text}
    </Button>
  );
}
