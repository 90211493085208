import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Refresh from '@mui/icons-material/Refresh';
import version from 'staticFiles/version.txt';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { clearApplicationCache, refreshSWAndReload } from './utils';

export default function Footer({ minimal = false }) {
  const { t } = useTranslation();
  const [appVersion, setappVersion] = useState('');
  const [swWaiting, setSwWaiting] = useState(undefined);

  useEffect(() => {
    fetch(version)
      .then((r) => r.text())
      .then((text) => {
        setappVersion(text);
      });

    // If we see that the service worker is waiting
    // then we can display a more visible button than the refresh at the bottom
    window.addEventListener('onSwUpdate', (event) => {
      setSwWaiting(event.detail.waiting);
    });
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        if (serviceWorkerRegistration.waiting) {
          setSwWaiting(serviceWorkerRegistration.waiting);
        }
      });
    }
    return () => window.removeEventListener('onSwUpdate', (event) => {
      setSwWaiting(event.detail.waiting);
    });
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 5,
        mb: 3,
      }}
    >
      {minimal === false && (
        <Typography variant="caption">
          {t('labox-apps')}
          <MUILink
            component={Link}
            to="/contact"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            contact@labox-apps.com
          </MUILink>
        </Typography>
      )}

      <Typography variant="caption">
        {t('settings.app_version', { appVersion })}
      </Typography>

      <Button
        variant="outlined"
        onClick={refreshSWAndReload}
        endIcon={<Refresh />}
        sx={{ my: 1 }}
      >
        <Typography variant="caption">
          {t('reload')}
        </Typography>
      </Button>

      {minimal === false && (
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: 1,
            mt: 1,
          }}
        >
          <Button
            sx={{ flexGrow: 1 }}
            variant="contained"
            color="primary"
            size="small"
            component={Link}
            to="/cgu"
            endIcon={<PdfIcon fontSize="small" />}
          >
            {t('welcome.gtcu')}
          </Button>
          <Button
            sx={{ flexGrow: 1 }}
            variant="contained"
            component={Link}
            to="/cgv"
            color="primary"
            size="small"
            endIcon={<PdfIcon fontSize="small" />}
          >
            {t('welcome.cgv')}
          </Button>
          <Button
            sx={{ flexGrow: 1 }}
            variant="contained"
            component={Link}
            to="/privacy-policy"
            color="primary"
            size="small"
            endIcon={<PdfIcon fontSize="small" />}
          >
            {t('welcome.privacy_policy')}
          </Button>
        </Box>
      )}
      <Button
        variant="text"
        size="small"
        onClick={clearApplicationCache}
        endIcon={<Refresh />}
        sx={{ my: 1 }}
      >
        <Typography variant="caption">
          {t('clear_cache')}
        </Typography>
      </Button>
      <Snackbar
        open={swWaiting}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="info"
          action={(
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setSwWaiting(undefined)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <Typography variant="span">
              {t('new_version')}
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                try {
                  swWaiting.postMessage({ type: 'SKIP_WAITING' });

                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                } catch (error) {
                  console.error('Error during service worker update:', error);
                  window.location.reload();
                }
              }}
              endIcon={<Refresh />}
            >
              {t('reload')}
            </Button>
          </Box>
        </Alert>
      </Snackbar>
    </Box>
  );
}
