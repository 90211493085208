import { unitFormatPrecision, unitMasks } from 'components/units/UnitDefinitionsAndMasks';
import {
  round, roundNoFixed, searchInCompartmentArray,
} from 'components/utils';
import { unit } from 'mathjs';
import decimalsMask from './DecimalsMask';
import { GlobalActions } from 'global-state/actions';
import { GlobalState } from 'global-state/store';
import { UseFormSetValue } from 'react-hook-form';
import { FormInputs } from './CompartmentValidationSchema';
import { Dispatch, SetStateAction } from 'react';

export function initialFormValues(volumeUnit: string, initaddOrRemoveTabValue: () => string) {
  return {
    height: {
      value: '',
      unit: 'mm',
      precision: 0,
    },
    volume: {
      value: '',
      unit: volumeUnit,
      precision: 4,
    },
    volumeToAdd: {
      isPureAlcohol: false,
      isAddVolume: initaddOrRemoveTabValue() === 'add',
      value: '',
      unit: volumeUnit,
      precision: 4,
    },
    tavInputs: {
      isElectronicDensimeter: false,
      measuredTav: {
        value: '',
        unit: 'TAV',
        precision: decimalsMask.TAV,
      },
      tavTemperature: {
        value: '',
        unit: 'celsius',
        precision: decimalsMask.celsius,
      },
      liquidTemperature: {
        value: '',
        unit: 'celsius',
        precision: decimalsMask.celsius,
      },
    },
    results: {
      volume: {
        value: '',
        unit: volumeUnit,
        precision: 4,
      },
      height: {
        value: '',
        unit: 'mm',
        precision: 0,
      },
    },
    paConversionResults: {},
    extraFields: [],
  }
}

export function convert(
  value: number,
  arrayToSearch: any[],
  conversion: any,
  inputUnitLabel: string,
  inputUnitCompute: string,
  outputUnitLabel: string,
  outputUnitCompute: string,
): number {
  const inputValue = round(
    unit(Number(value), inputUnitLabel).toNumber(inputUnitCompute),
    unitFormatPrecision,
  );
  const outputValue = searchInCompartmentArray(
    inputValue,
    arrayToSearch,
    conversion,
  );
  return roundNoFixed(
    unit(outputValue, outputUnitCompute).toNumber(outputUnitLabel),
    unitMasks[outputUnitLabel as keyof typeof unitMasks],
  );
}

export async function submit(
  data: any,
  setValue: UseFormSetValue<FormInputs>,
  setHighlightment: Dispatch<SetStateAction<{ height: boolean; volume: boolean; }>>,
  removeHighlight: (field: string) => void,
  fieldUtils: any,
  globalState: GlobalState,
  globalActions: GlobalActions,
  t: any,
  laboxAlcoholometryApiCall: any,
  minAndMaxes: any,
  setError: any,
) {
  let finalVolume = Number(data.volume.value);
  let finalHeight = Number(data.height.value);
  
  if (data.height.value !== '') {
    const calcul = convert(
      finalHeight,
      fieldUtils.volume.arrayToSearch,
      fieldUtils.volume.conversion,
      fieldUtils.volume.searchUnitLabel,
      fieldUtils.volume.searchUnitCompute,
      fieldUtils.volume.unitLabel,
      fieldUtils.volume.unitCompute,
    )
    setValue('volume.value', calcul.toString());
    finalVolume = calcul;
    setHighlightment({
      'height': false,
      'volume': true,
    });
    setTimeout(removeHighlight, 300, 'volume');
  } else if ((data.volume.value !== '') ) {
    const calcul = convert(
      finalVolume,
      fieldUtils.height.arrayToSearch,
      fieldUtils.height.conversion,
      fieldUtils.height.searchUnitLabel,
      fieldUtils.height.searchUnitCompute,
      fieldUtils.height.unitLabel,
      fieldUtils.height.unitCompute,
    )
    setValue('height.value', calcul.toString());
    finalHeight = calcul;
    setHighlightment({
      'height': true,
      'volume': false,
    });
    setTimeout(removeHighlight, 300, 'height');
  }

  let volumeAtTempValue = Number(data.volumeToAdd.value);
  let abvAt20cValue;

  const tavInputsPresent = data.tavInputs.measuredTav.value ? true : false;

  if(globalState.networkState !== 'offline' 
    && tavInputsPresent) {

    if (!/^20(\.0{1,2})?$/.test(data.tavInputs.tavTemperature.value)) {
      const resTAVConversion = await laboxAlcoholometryApiCall({
        call: 'convertABVToTemperature',
        form: {
          abvValue: data.tavInputs.measuredTav.value,
          abvUnit: 'TAV',
          abvOutUnit: 'TAV',
          temperatureInValue: data.tavInputs.tavTemperature.value,
          temperatureOutValue: '20',
          temperatureInUnit: 'celsius',
          temperatureOutUnit: 'celsius',
          abvPrecision: decimalsMask.TAV,
          temperatureInPrecision: decimalsMask.celsius,
        },
        app: 'digitank-gauged-containers',
      });
      abvAt20cValue = resTAVConversion.data.result.ABVAt20c.value;
    } else {
      abvAt20cValue = data.tavInputs.measuredTav.value;
    }

  }

  if (data.volumeToAdd.value !== '') {
    if (data.volumeToAdd.isPureAlcohol) {
      if(globalState.networkState === 'offline') {
        setValue('paConversionResults', {});
        globalActions.setSnackbarMessage({message: t('forms.need_network'), severity: 'error'});
        return;
      }
     
      const paToVolResults = await laboxAlcoholometryApiCall({
        call: 'getVolumeAtTempFromAbvAndPureAlcoholAtTemperature',
        form: {
          volumeAPAt20cValue: volumeAtTempValue,
          temperatureValue: data.tavInputs.liquidTemperature.value,
          abvAt20cValue,
          volumeAPAt20cUnit: fieldUtils.volume.unitLabel,
          temperatureUnit: 'celsius',
          abvAt20cUnit: 'TAV',
          volumeAPAt20cPrecision: decimalsMask[fieldUtils.volume.unitLabel as keyof typeof decimalsMask],
          temperaturePrecision: decimalsMask.celsius,
          abvAt20cPrecision: decimalsMask.TAV,
          intermediateRounding: true,
        },
        app: 'digitank-gauged-containers',
      });

      volumeAtTempValue = Number(paToVolResults.data.result.volumeAtTemp.value);

      const newPAConversionResults = {
        abvAt20c: {
          value: abvAt20cValue,
          unit: 'TAV',
          precision: decimalsMask.TAV,
        },
      };

      setValue('paConversionResults', newPAConversionResults);
    }

    if (data.volumeToAdd.isAddVolume) {
      finalVolume = finalVolume + volumeAtTempValue;
    } else {
      finalVolume = finalVolume - volumeAtTempValue;
    }

    finalVolume = roundNoFixed(finalVolume, unitMasks[fieldUtils.volume.unitLabel as keyof typeof unitMasks]);

    finalHeight = convert(
      finalVolume,
      fieldUtils.height.arrayToSearch,
      fieldUtils.height.conversion,
      fieldUtils.height.searchUnitLabel,
      fieldUtils.height.searchUnitCompute,
      fieldUtils.height.unitLabel,
      fieldUtils.height.unitCompute,
    );
  }

  if(tavInputsPresent) {
    if(globalState.networkState !== 'offline') {

      const volumeUnit = fieldUtils.volume.unitLabel as keyof typeof decimalsMask;

      const volumeToPaRes = await laboxAlcoholometryApiCall({
        call: 'convertVolumeToVolumeAtTemp',
        form: {
          abvAt20cValue,
          abvAt20cUnit: 'TAV',
          volumeValue: finalVolume,
          volumeUnit,
          temperatureInValue: data.tavInputs.liquidTemperature.value,
          temperatureOutValue: '20',
          temperatureInUnit: 'celsius',
          temperatureOutUnit: 'celsius',
          abvPrecision: decimalsMask.TAV,
          volumePrecision: decimalsMask[volumeUnit],
          temperatureInPrecision: decimalsMask.celsius,
          intermediateRounding: true,
        },
        app: 'digitank-gauged-containers',
      });

      const newPAConversionResults = {
        ...volumeToPaRes.data.result,
        abvAt20c: {
          value: abvAt20cValue,
          unit: 'TAV',
          precision: decimalsMask.TAV,
        },
      };

      setValue('paConversionResults', newPAConversionResults);
    } else {
      setValue('paConversionResults', {});
    }
  }

  setValue('results.volume.value', finalVolume.toString());
  setValue('results.height.value', finalHeight.toString());
  if(data.volumeToAdd.value !== '') {
    if (Number(finalVolume) > minAndMaxes.maxVolume) {
      setError('volumeToAdd.value', { type: 'custom', message: t('operation.final_volume_too_high', { maxVolume: minAndMaxes.maxVolume }) });
    } else if (Number(finalVolume) < minAndMaxes.minVolume) {
      setError('volumeToAdd.value', { type: 'custom', message: t('operation.final_volume_too_low', { minVolume: minAndMaxes.minVolume }) });
    }
  }
}