import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  Paper, Radio, Typography, Link as MUILink, Checkbox,
} from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import ConfirmIcon from '@mui/icons-material/TaskAlt';
import { Link, useNavigate } from 'react-router-dom';
import AddCardIcon from '@mui/icons-material/AddCard';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import PriceGraph from './PriceGraph';

export default function CheckoutPage() {
  const [globalState, globalActions] = useGlobal();
  const analytics = getAnalytics();
  const functions = useFunctions();
  const firestore = useFirestore();
  functions.region = 'europe-west1';
  const checkoutSession = httpsCallable(functions, 'gaugedContainersCheckoutSession');
  const [loadingStatus, setloadingStatus] = useState('initial');
  const { t } = useTranslation();
  const [billingMethod, setBillingMethod] = useState('stripeBilling');
  const navigate = useNavigate();
  const [gtcuAccepted, setGtcuAccepted] = useState(false);

  const updateBillingMethod = async (method) => {
    const orgPath = `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers`;
    const docRef = doc(firestore, orgPath);

    try {
      await updateDoc(docRef, {
        billingMethod: method,
      });
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    }
  };

  // This is called only in case of manual billing, else this connection will be done in the subscription webhook
  const connectWithGauger = async () => {
    const connectToGaugerCall = httpsCallable(functions, 'connectToGaugerCall');
    const resCreateOrga = await connectToGaugerCall({
      organizationId: globalState.activeOrganization,
      gaugerOrganizationId: globalState.onboardingGaugerOrganizationId,
      appName: 'digitank-gauged-containers',
    });
    globalActions.setGaugerCollaborations([resCreateOrga.data.gaugerOrganizationId]);
  };

  const handleCheckoutClick = async () => {
    if (billingMethod === 'stripeBilling') {
      await handleStripeCheckout();
    } else {
      await connectWithGauger();
      await handleManualBilling();
    }
  };

  const handleStripeCheckout = async () => {
    logEvent(analytics, 'clicked_checkout_session', {
      appName: 'Digitank, Gauged Containers',
      organization: globalState.activeOrganization,
    });
    await updateBillingMethod('stripeSubscription');
    try {
      setloadingStatus('loading');
      logEvent(analytics, 'confirm_manual_billing_subscription', {
        appName: 'Digitank, Gauged Containers',
        organization: globalState.activeOrganization,
      });
      const result = await checkoutSession({
        fbo: globalState.activeOrganization,
      });
      window.location.href = result.data.url;
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setloadingStatus('loaded');
    }
  };

  const handleManualBilling = async () => {
    setloadingStatus('loading');
    await updateBillingMethod('manual');
    setloadingStatus('loaded');
    navigate('/admin');
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 3,
      width: '100%',
      mt: 4,
    }}
    >
      <Typography variant="h4">
        {t('subscription.title')}
      </Typography>
      <Typography sx={{ textAlign: 'left' }}>
        {t('subscription.info')}
      </Typography>
      <Box sx={{ height: 'auto', width: { xs: '100%', md: 800 } }}>
        <PriceGraph />
      </Box>
      <Typography sx={{ textAlign: 'left' }}>
        {t('subscription.info_certificate_integration', { price: 15 })}
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: 1, display: 'flex', flexDirection: 'column', width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', p: 2,
          }}
          onClick={() => setBillingMethod('stripeBilling')}
        >
          <Typography sx={{ textAlign: 'left', flexBasis: '75%' }}>
            {t('subscription.automatic_billing', { price: 15 })}
          </Typography>
          <Box sx={{ flexBasis: '25%' }}>
            <Radio checked={billingMethod === 'stripeBilling'} sx={{ fontSize: 30 }} />
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: '#F2F5F8',
          }}
          onClick={() => setBillingMethod('manual')}
        >
          <Box sx={{ flexBasis: '75%' }}>
            <Typography sx={{ textAlign: 'left' }}>
              {t('subscription.no_automatic_billing', { price: 15 })}
            </Typography>
            {billingMethod === 'manual' && (
              <Typography sx={{ textAlign: 'left', mt: 2 }}>
                {t('subscription.manual_billing_method', { price: 15 })}
              </Typography>
            )}
          </Box>
          <Box sx={{ flexBasis: '25%' }}>
            <Radio checked={billingMethod === 'manual'} sx={{ fontSize: 30 }} />
          </Box>
        </Box>
      </Paper>

      {billingMethod === 'manual' && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            label="accept_gtcu"
            name="reportCertificateExpiration.active"
            checked={gtcuAccepted}
            onChange={(event) => setGtcuAccepted(event.target.checked)}
          />
          <Typography sx={{ textAlign: 'left', mt: 2 }}>
            <Trans i18nKey="subscription.accept_cgv">
              <MUILink
                component={Link}
                to="/cgu"
                color="primary"
                sx={{ fontWeight: 'bold' }}
              >
                cgu
              </MUILink>
              <MUILink
                component={Link}
                to="/cgv"
                color="primary"
                sx={{ fontWeight: 'bold' }}
              >
                cgv
              </MUILink>
              <MUILink
                component={Link}
                to="/privacy-policy"
                color="primary"
                sx={{ fontWeight: 'bold' }}
              >
                confidentiality
              </MUILink>
            </Trans>
          </Typography>
        </Box>
      ) }

      <LoadingButton
        disabled={billingMethod === 'manual' && !gtcuAccepted}
        loading={loadingStatus === 'loading'}
        variant="contained"
        color="primary"
        size="large"
        onClick={handleCheckoutClick}
        endIcon={billingMethod === 'manual' ? <ConfirmIcon /> : <AddCardIcon />}
      >
        {billingMethod === 'manual' ? t('finish') : t('continue') }
      </LoadingButton>
    </Box>
  );
}
