import * as yup from 'yup';
import { roundNoFixed } from '../../utils';

export interface TAVInputs {
  isElectronicDensimeter: boolean;
  measuredTav: {
    value: string;
    unit: string;
    precision: number;
  };
  tavTemperature: {
    value: string;
    unit: string;
    precision: number;
  };
  liquidTemperature: {
    value: string;
    unit: string;
    precision: number;
  };
}

export interface PAResults {
  abvAt20c?: { value: number; unit: string };
  volumeAtTemp?: {
    temperature: { value: number; unit: string };
    value: number;
    unit: string;
  };
  volumeAt20c?: { 
    value: number; 
    unit: string;
    temperature: { value: number; unit: string };
  };
  pureAlcoholVolumeAt20c?: { value: number; unit: string };
}

export interface FormInputs {
  height: {
    value: string;
    unit: string;
    precision: number;
  };
  volume: {
    value: string;
    unit: string;
    precision: number;
  };
  volumeToAdd: {
    isPureAlcohol: boolean;
    isAddVolume: boolean;
    value: string;
    unit: string;
    precision: number;
  };
  tavInputs: TAVInputs;
  results: {
    volume: {
      value: string;
      unit: string;
      precision: number;
    };
    height: {
      value: string;
      unit: string;
      precision: number;
    };
  };
  paConversionResults: PAResults;
  extraFields: any;
}

export default function validationSchema(
  t: any,
  minHeight: number,
  maxHeight: number,
  minVolume: number,
  maxVolume: number,
) {
  const minHeightRounded = roundNoFixed(minHeight, 4);
  const maxHeightRounded = roundNoFixed(maxHeight, 4);
  const minVolumeRounded = roundNoFixed(minVolume, 4);
  const maxVolumeRounded = roundNoFixed(maxVolume, 4);

  const validationPhrases = {
    heightValidationPhrase: t('validations.height', {
      minHeight: minHeightRounded,
      maxHeight: maxHeightRounded,
    }),
    volumeValidationPhrase: t('validations.volume', {
      minVolume: minVolumeRounded,
      maxVolume: maxVolumeRounded,
    }),
  };

  const heightSchema = yup.object().shape({
    value: yup.string()
      .test(
        'min',
        validationPhrases.heightValidationPhrase,
        value => value === '' || (Number(value) >= minHeightRounded)
      )
      .test(
        'max',
        validationPhrases.heightValidationPhrase,
        value => value === '' || (Number(value) <= maxHeightRounded)
      )
      .defined(validationPhrases.heightValidationPhrase),
    // .when('volume.value', {
    //   is: (volumeValue: string) => !!volumeValue,
    //   then: schema => schema.defined(),
    //   otherwise: schema => schema.required(validationPhrases.heightValidationPhrase),
    // }),
    unit: yup.string().required(validationPhrases.heightValidationPhrase),
    precision: yup.number().required(validationPhrases.heightValidationPhrase),
  });

  const volumeSchema = yup.object().shape({
    value: yup.string()
      .test(
        'min',
        validationPhrases.volumeValidationPhrase,
        value => value === '' || (Number(value) >= minVolumeRounded)
      )
      .test(
        'max',
        validationPhrases.volumeValidationPhrase,
        value => value === '' || (Number(value) <= maxVolumeRounded)
      )
      .defined(validationPhrases.heightValidationPhrase),
    // .when('height.value', {
    //   is: (heightValue: string) => !!heightValue,
    //   then: schema => schema.defined(),
    //   otherwise: schema => schema.required(validationPhrases.volumeValidationPhrase),
    // }),
    unit: yup.string().required(validationPhrases.volumeValidationPhrase),
    precision: yup.number().required(validationPhrases.volumeValidationPhrase),
  });

  return yup.object().shape({
    height: heightSchema,
    volume: volumeSchema,
    volumeToAdd: yup.object().shape({
      isPureAlcohol: yup.boolean().required(),
      isAddVolume: yup.boolean().required(),
      value: yup.string().defined(),
      unit: yup.string().required(),
      precision: yup.number().required(),
    }),
    tavInputs: yup.object().shape({
      isElectronicDensimeter: yup.boolean().required(),
      measuredTav: yup.object().shape({
        value: yup.string().defined(),
        unit: yup.string().required(),
        precision: yup.number().required(),
      }),
      tavTemperature: yup.object().shape({
        value: yup.string().defined(),
        unit: yup.string().required(),
        precision: yup.number().required(),
      }),
      liquidTemperature: yup.object().shape({
        value: yup.string().defined(),
        unit: yup.string().required(),
        precision: yup.number().required(),
      }),
    }),
    results: yup.object().shape({
      volume: yup.object().shape({
        value: yup.string().defined(),
        unit: yup.string().required(),
        precision: yup.number().required(),
      }),
      height: yup.object().shape({
        value: yup.string().defined(),
        unit: yup.string().required(),
        precision: yup.number().required(),
      }),
    }),
    paConversionResults: yup.object().defined(),
    extraFields: yup.array().defined(),
  });
}